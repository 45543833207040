import React from 'react';
import * as styles from './Bank.module.scss';
import { LightBulb, ChevronDoubleRight, No, Check } from '../../../svgs';

const no = <No className="inline-block w-6 h-6 mr-2 stroke-current text-red-300" />;
const yes = <Check className="inline-block w-6 h-6 mr-2 stroke-current text-green-300" />;
const info = <LightBulb className="inline-block w-6 h-6 mr-2 stroke-current" />;

const Bank = ({ bank, index, isRanking }) => {
  const {
    name,
    logoUrl,
    hasEnglish,
    accountNumberCountry,
    onlineRegistration,
    hasIdeal,
    hasMaestro,
    link,
    price,
    notes,
    moreInfoUrl,
  } = bank;

  return (
    <div className="card col-span-1 row-span-3 shadow-lg xl:col-span-2 bg-base-100 mb-10">
      <div className="card-body">
        <div className="flex justify-center sm:justify-between mb-4">
          <h3 className="my-4 text-3xl font-bold card-title hidden sm:block text-black">
            <a
              href={link}
              target="_blank"
              rel="nofollow noreferrer"
            >
              {index !== undefined && `${index + 1} `}
              {name}
            </a>                    
          </h3>
  
          <div
            className={styles.logoContainer}
            style={{ backgroundImage: `url("${logoUrl}")`, }}
          />
        </div>
  
        <div className="prose prose-md">
          <div>
            {hasEnglish ? yes : no}
            Aplikacja po angielsku 🇬🇧
          </div>
          <div>
            {onlineRegistration ? yes : no}
            <b>Rejestracja online</b>
          </div>
          <div>
            {hasIdeal ? yes : no}
            <a
              href="/2021/04/04/co-to-jest-ideal-przewodnik-po-holenderskich-platnosciach-online"
              target="_blank"
            >
              Płatności internetowe Ideal
            </a>
          </div>
          <div>
            {accountNumberCountry === 'NL' ? yes : no}
            Holenderski numer konta 🇳🇱
          </div>
          <div>
            {hasMaestro ? yes : no}
            <a
              href="/2021/02/21/dlaczego-moja-karta-nie-dziala-w-holandii-czyli-wszystko-o-niderlandzkiej-karcie-maestro"
              target="_blank"
            >
              Karta Maestro
            </a>
            {' '}
            w ofercie
          </div>
          <div>
            {info} Koszt: {price} miesięcznie
          </div>
          {
            notes.map(
              ({ note, isBold }) => (
                <div key={note}>
                  {info}
                  {' '}
                  {isBold && <b>{note}</b>}
                  {!isBold && note}
                </div>
              )
            )
          }
        </div>
  
        <div className="justify-end space-x-2 card-actions">
          {moreInfoUrl && isRanking && (
            <a className="btn btn-primary" href={moreInfoUrl} target="_blank">
              Więcej informacji o banku
            </a>
          )}
          <a className="btn btn-accent" href={link} target="_blank" rel="nofollow noreferrer">
            Do banku
            <ChevronDoubleRight className="inline-block w-6 h-6 ml-2 stroke-current" />
          </a>
        </div>
      </div>
    </div>
  );  
};

export default Bank;
