const banks = [
  {
    name: 'bunq',
    logoUrl: '/img/dutch-banks/bunq-logo.jpeg',
    hasEnglish: true,
    accountNumberCountry: 'NL',
    onlineRegistration: true,
    hasIdeal: true,
    hasMaestro: true,
    link: '/out/bunq',
    price: '€ 2,99 / 8.99',
    notes: [
      { note: 'brak potrzeby meldunku', isBold: true },
      { note: 'brak potrzeby numer BSN', isBold: true },
      { note: 'nowoczesna aplikacja' },
      { note: 'płatności Google pay / Apple pay' },
      { note: 'dużo sposobów na dodanie pieniędzy do konta' },
      { note: 'nowoczesny bank internetowy' },
    ],
    moreInfoUrl: '/konta/bunq',
  },
  {
    name: 'ABN Amro',
    logoUrl: '/img/dutch-banks/abn-amro-logo.svg',
    hasEnglish: true,
    accountNumberCountry: 'NL',
    onlineRegistration: true,
    hasIdeal: true,
    hasMaestro: true,
    link: '/out/abn-amro',
    price: '€ 2.95',
    notes: [
      { note: 'brak potrzeby meldunku', isBold: true },
      { note: 'potrzebny numer BSN', isBold: true },
      { note: 'tradycyjny bank' },
      { note: 'płatności Google pay / Apple pay' },
    ],
  },
  {
    name: 'N26',
    logoUrl: '/img/dutch-banks/n26-logo.svg',
    hasEnglish: true,
    accountNumberCountry: 'DE',
    onlineRegistration: true,
    hasIdeal: false,
    hasMaestro: true,
    link: '/out/n26',
    price: '€ 0',
    notes: [
      { note: 'brak potrzeby meldunku', isBold: true },
      { note: 'brak potrzeby numer BSN', isBold: true },
      { note: 'płatności Google pay / Apple pay' },
      { note: 'nowoczesny bank internetowy' },
      { note: 'niemiecki bank, niemiecki numer konta', isBold: true },
    ],
  },
  {
    name: 'Revolut',
    logoUrl: '/img/dutch-banks/revolut-logo.svg',
    hasEnglish: true,
    accountNumberCountry: 'LT',
    onlineRegistration: true,
    hasIdeal: false,
    hasMaestro: true,
    link: '/out/revolut',
    price: '€ 0',
    notes: [
      { note: 'brak potrzeby meldunku', isBold: true },
      { note: 'brak potrzeby numer BSN', isBold: true },
      { note: 'płatności Google pay / Apple pay' },
      { note: 'nowoczesny bank internetowy' },
      { note: 'litewski bank, litewski numer konta', isBold: true },
    ],
  },
  {
    name: 'Openbank',
    logoUrl: '/img/dutch-banks/openbank-logo.svg',
    hasEnglish: false,
    accountNumberCountry: 'NL',
    onlineRegistration: true,
    hasIdeal: false,
    hasMaestro: true,
    link: '/out/openbank',
    price: '€ 0',
    notes: [
      { note: 'brak potrzeby meldunku', isBold: true },
      { note: 'potrzebny numer BSN', isBold: true },
      { note: 'płatności Apple pay' },
      { note: 'nowoczesny bank internetowy' },
    ],
  },
  {
    name: 'ING NL',
    logoUrl: '/img/dutch-banks/ing-logo.jpg',
    hasEnglish: false,
    accountNumberCountry: 'NL',
    onlineRegistration: false,
    hasIdeal: true,
    hasMaestro: true,
    link: '/out/ing',
    price: '€ 2,70 / 3,15',
    notes: [
      { note: 'potrzebny meldunek i jego potwierdzenie z urzędu', isBold: true },
      { note: 'potrzebny numer BSN', isBold: true },
      { note: 'tradycyjny bank' },
      { note: 'płatności Apple pay / płatności android NFC' },
      { note: 'aplikacja częściowo po angielsku' },
    ],
  },
  {
    name: 'Rabobank',
    logoUrl: '/img/dutch-banks/rabobank-logo.svg',
    hasEnglish: false,
    accountNumberCountry: 'NL',
    onlineRegistration: false,
    hasIdeal: true,
    hasMaestro: true,
    link: '/out/rabobank',
    price: '€ 1,70',
    notes: [
      { note: 'potrzebny meldunek', isBold: true },
      { note: 'potrzebny numer BSN', isBold: true },
      { note: 'tradycyjny bank' },
      { note: 'płatności Apple pay / płatności android NFC' },
    ],
  },
  {
    name: 'SNS',
    logoUrl: '/img/dutch-banks/sns-logo.jpg',
    hasEnglish: false,
    accountNumberCountry: 'NL',
    onlineRegistration: false,
    hasIdeal: true,
    hasMaestro: true,
    link: '/out/sns',
    price: '€ 2,25 / 3,10',
    notes: [],
    notes: [
      { note: 'potrzebny meldunek i jego potwierdzenie z urzędu i BSN lub umowa o pracę w Holandii', isBold: true },
      { note: 'tradycyjny bank' },
      { note: 'płatności android NFC' },
    ],
  },
];

export default banks;
