import React from 'react';
import Layout from '../../components/Layout';
import Bank from '../../components/ranking/Bank';
import SEO from '../../components/seo';
import banks from '../../config/banks';
import * as styles from '../../templates/blog.module.scss';
import { ChevronDoubleRight } from '../../svgs';

const bank = banks.find(({ name }) => name === 'bunq');

const title = 'Bank bunk – konto bankowe w Holandii';

const CustomPage = () => {
  return (
    <Layout>
      <SEO title={title} />
      <h1 className="text-3xl font-bold text-black">
        {title}
      </h1>

      <Bank bank={bank} />

      <div className={`prose ${styles.content}`}>
        <p>
          Bunq to holenderski bank internetowy oferujący konta dla osób prywatnych, jak i firm od 2015 roku. Bunq posiada licencją bankową wydaną przez Bank Holenderski. Uważam, że jest to numer jeden, jeśli chodzi o banki w Holandii dla obcokrajowców. Po założeniu konta można zamówić kartę Maestro oraz Visa (która przydaje się do płatności w internecie). Jako że jest to bank internetowy, możemy go obsługiwać przez aplikację lub stronę internetową. Aby otworzyć konto, należy mieć adres w Holandii, zakładanie konta jest bardzo szybkie, a na kartę czeka się tylko kilka dni.
        </p>

        <h3>
          Pakiet bunq Easy Bank
        </h3>
        <p>
          Pakiet bunq Easy Bank to najnowszy plan od bunq. Jest on najtańszy i zawiera kilka podstawowych udogodnień, aczkolwiek dla przeciętnej osoby powinny one wystarczyć. W cenie dostajemy holenderskie konto bankowe z jedną kartą. Za każą wypłatę z bankomatu, użytkownik musi zapłacić.
        </p>

        <h3>
          Pakiet bunq Easy Money
        </h3>
        <p>
          Pakiet bunq Easy Money to prawdopodobnie najlepszym pakietem dla osoby prywatnej. Pakiet kosztuje 8,99 EUR miesięcznie i jest całkowicie darmowy przez pierwszy miesiąc. Ponadto konto można w każdej chwili zlikwidować. Konto ma zwykły holenderski numer bankowy zaczynający się od NL… Więc jeśli ktoś potrzebuje otrzymywać pensję w Holandii, jest to bardzo dobry wybór.
        </p>
        <p>
          Cechy tego konta to:
        </p>
        <ul>
          <li>3 karty bankowe, w których możesz wybrać kartę Debit Mastercard, Bunq Easy Travel Card i Maestro</li>
          <li>25 rachunków bankowych, każde z własnym IBAN</li>
          <li>10 darmowych wypłat gotówki miesięcznie</li>
          <li>zapłacić przez Apple Pay i Google Pay</li>
          <li>wpłacaj do 100 € miesięcznie, aby otrzymać darmowe pieniądze na swoje konto</li>
          <li>darmowe przelewy SEPA w obrębie Holandii i całej Unii Europejskiej</li>
        </ul>

        <h3>
          Karty
        </h3>
        <p>
          Z wyjątkiem karty podróżnej Easy Money (9,99 EUR), większość kart bunq jest bezpłatna. Prawdopodobnie nigdy nie będziesz potrzebował zamawiać żadnej płatnej karty.
        </p>

        <h3>
          Otwarcie kont bunq
        </h3>
        <p>
          Cały proces jest elektroniczny, nie trzeba się nigdzie udawać. Należy przejść na stronę banku, podać numer telefonu i postępować zgodnie z poleceniami.
        </p>

        <h3>
          Podsumowanie
        </h3>
        <p>
          Bunq to bardzo dobra opcja dla osób poszukujących banku, w którym można otworzyć dużą liczbę rachunków bankowych za stałą kwotę miesięcznie oraz dla osób, które szukają banku z angielskim interfejsem niewymagającego potwierdzenia zameldowania. Otrzymujesz również odsetki (0,27%) na wszystkich swoich kontach, które są wypłacane co miesiąc. Bezpłatna karta kredytowa lub debetowa jest również miłym dodatkiem dla wielu osób.
        </p>
      </div>

      <a className="btn btn-accent" href={bank.link} target="_blank" rel="nofollow noreferrer">
        Do banku
        <ChevronDoubleRight className="inline-block w-6 h-6 ml-2 stroke-current" />
      </a>  
    </Layout>
  );
};

export default CustomPage
